@import "./assets/variables/color.scss";

.wrapperContainer {
  @media screen and (min-width: 992px) {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-bg;
    gap: 16px;
  }
}

// poster

.posterContainer {
  position: fixed;
  width: 100vw;
  height: 50vh;

  @media (min-width: 992px) and (max-width: 1370px) {
    width: 50vw;
    height: 95vh;
    position: static;
    border-radius: 24px;
  }

  @media screen and (min-width: 1370px) {
    width: 50vw;
    height: 80vh;
    position: static;
    border-radius: 24px;
  }
}

.optionsContainer {
  position: absolute;
  bottom: 0;
  background-color: $white;
  width: 100%;
  height: 55vh;
  border-radius: 24px 24px 0 0;
  padding: 24px 0;

  @media (min-width: 992px) and (max-width: 1370px) {
    position: relative;
    width: 45vw;
    height: 95vh;
    border-radius: 24px;
    padding: 24px 0;
  }

  @media screen and (min-width: 1370px) {
    position: relative;
    width: 40vw;
    height: 80vh;
    border-radius: 24px;
    padding: 32px 0;
  }
}

.optionsContainer::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @media (min-width: 992px) {
    border-radius: 24px;
  }
}

// question
.topBarContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media screen and (min-width: 992px) {
    font-size: calc(14px + 4 * (100vw / 1280));
    margin-bottom: 16px;
  }
}

.back {
  display: none;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (min-width: 992px) {
    cursor: pointer;
    display: block;
  }
}

// options

.optionsWrapper {
  margin-top: 16px;

  @media (min-width: 992px) and (max-width: 1370px) {
    margin-top: 0;
  }

  @media (min-width: 992px) and (max-height: 850px) {
    margin-top: 0px;
  }
}

.optionsFirstScreen {
  padding: 0 16px;

  @media screen and (min-width: 992px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
  }
}

.buttonAbsolute > button {
  margin-top: 48px;
  width: calc(100% - 32px);
}

.onScrollOption::before {
  background-color: rgba(0, 0, 0, 0.6);
  top: 8px;
  transition: all 0.2s;
}

.header {
  padding: 0 16px;

  @media screen and (min-width: 992px) {
    padding: 0 5%;
  }
}

.buttonContainer {
  margin: 0 16px;

  @media (min-width: 992px) and (max-width: 1400px) {
    position: absolute;
    bottom: 16px;
    width: 95%;
  }

  @media screen and (min-width: 1400px) {
    position: absolute;
    bottom: 48px;
    width: 95%;
  }
}

.titleWrapper {
  @media screen and (min-width: 992px) {
    min-height: 112px;
  }
}

.optionsItemtitle {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;

  @media screen and (min-width: 992px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.optionsItemText {
  display: block;
  font-size: 14px;
  line-height: 24px;

  @media screen and (min-width: 992px) {
    font-size: 16px;
  }
}

.buttonNotfix {
  position: static;
  margin: 24px 0;

  @media screen and (min-width: 992px) {
    margin: 0;
  }
}

.bgWhite {
  background-color: $white;
}
